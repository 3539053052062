import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import Meta from "../components/layout/meta";
import UserEnrollments from "./userenrollments";

import { useUserEnrollments } from "../lib/context/userenrollmentscontext";
import { useCity } from "../lib/context/citycontext";

export default function Layout({
    pageClass,
    menuPath,
    isDarkHeader,
    topMenuItems,
    sideMenuItems,
    bottomMenuBlocks,
    footerLinkItems,
    counters,
    cities,
    // currentCity,
    user,
    sharesInfo,
    hideUserEnrolledGames,
    csrftoken,
    settings,
    children,
}) {
    const headerCounters = counters.map((counter) => counter.header_code || "").join("");
    const topCounters = counters.map((counter) => counter.top_code || "").join("");
    const bottomCounters = counters.map((counter) => counter.bottom_code || "").join("");

    const { city } = useCity();
    const { userEnrollments } = useUserEnrollments();

    const enrollmentsWillShow = !hideUserEnrolledGames && userEnrollments;

    return (
        <>
            {topCounters && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: topCounters,
                    }}
                />
            )}

            <div className={pageClass}>
                <Meta
                    headerCounters={headerCounters}
                    title={settings.SITE_NAME}
                    metrikaId={settings.METRIKA_ID}
                    vkPixelId={settings.VK_PIXEL_ID}
                    user={user}
                />

                {user && user.telegram_start_link && (
                    <div
                        className={
                            "user-enrollments user-enrollments--telegram-link" +
                            (enrollmentsWillShow ? " pb-1" : "") +
                            (isDarkHeader ? " user-enrollments--dark" : "")
                        }
                    >
                        <div className="container-fluid">
                            Уведомления WhatsApp <span className="text-danger">сбоят</span>, для моментального получения уведомлений и переписки с
                            менеджером, включите, пожалуйста Telegram Бота{" "}
                            <a href={user.telegram_start_link} className="btn btn-sm btn-primary">
                                Включить
                            </a>
                        </div>
                        {user.queued_private_messages && (
                            <div className="container-fluid mt-1">
                                {user.queued_private_messages}, но получить эти сообщения вы сможете
                                только включив Telegram Бота.
                            </div>
                        )}
                    </div>
                )}

                {enrollmentsWillShow && (
                    <UserEnrollments enrollments={userEnrollments} isDark={isDarkHeader} key={Math.random()} />
                )}

                <div className={isDarkHeader ? "dark-plate" : ""}>
                    <Header
                        menuItems={topMenuItems}
                        sideMenuItems={sideMenuItems}
                        menuPath={menuPath}
                        cities={cities}
                        currentCity={city}
                        user={user}
                        sharesInfo={sharesInfo}
                        isDark={isDarkHeader}
                    />
                </div>

                {children}

                <Footer bottomMenuBlocks={bottomMenuBlocks} linkItems={footerLinkItems} currentCity={city} />
            </div>

            {bottomCounters && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: bottomCounters,
                    }}
                />
            )}

            <input type="hidden" id="csrftoken" value={csrftoken} />
        </>
    );
}
