export function isMeet4USite() {
    return process.env.NEXT_PUBLIC_IS_MEET4U_SITE === "true";
}

export function choosePlural(amount, variants) {
    if (typeof variants === "string") {
        variants = variants.split(",");
    }

    amount = Math.abs(amount);

    let variant;
    if (amount % 10 == 1 && amount % 100 != 11) {
        variant = 0;
    } else if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
        variant = 1;
    } else {
        variant = 2;
    }

    return variants[variant];
}

// returns [items to show, items hidden]
export function getItemsToShow(totalItems, maxInitialItems) {
    if (totalItems > maxInitialItems) {
        return [maxInitialItems - 1, totalItems - maxInitialItems + 1];
    }
    return [totalItems, 0];
}

export function linkifyText(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, i) => {
        if (part.match(urlRegex)) {
            return (
                <a href={part} key={i}>
                    {part}
                </a>
            );
        }
        return part;
    });
}
