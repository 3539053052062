import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Link from "next/link";
import { useRouter } from "next/router";

import { cancelEnrollment, fetchUserEnrollments } from "../lib/api/games";
import { gtmOnCancelEnrollment } from "../lib/api/gtm";

function CancelTimeLimitModal({ cancelTimeLimit, onHide }) {
    return (
        <Modal show centered className="modal--fancy" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Отмена записи на игру</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    К сожалению, до начала игры осталось менее {cancelTimeLimit}-х часов, поэтому отмена игры невозможна{" "}
                    согласно{" "}
                    <Link href="/user-agreement/">
                        <a>Пользовательскому соглашению</a>
                    </Link>
                    .
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Понятно
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function CancelConfirmModal({ enrollment, onCancel, onHide }) {
    return (
        <Modal show centered className="modal--fancy" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Отмена записи на игру</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы точно хотите отменить запись на игру?</p>
                <div className="mb-4">
                    <p className="text-primary fs-5">
                        <span className="fw-bold">{enrollment.game.full_name}</span>
                        {enrollment.game.host && (
                            <>
                                <br />
                                Ведущий: {enrollment.game.host.name}
                            </>
                        )}
                    </p>
                    {enrollment.game.place && <p>{enrollment.game.place.name}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" className="mx-3 mb-3" onClick={onCancel}>
                    Отменить
                </Button>
                <Button variant="success" className="mx-3 mb-3" onClick={onHide}>
                    &nbsp; &nbsp; Назад &nbsp; &nbsp;
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function UserEnrollment({ enrollment, onUpdate }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [cancelTimeLimit, setCancelTimeLimit] = useState(null);

    const cancelParticipation = async () => {
        setShowConfirmModal(false);

        const { game_type, price, cancel_time_limit } = await cancelEnrollment(enrollment.game.id);
        if (cancel_time_limit) {
            setCancelTimeLimit(cancel_time_limit);
            return;
        }

        if (game_type) {
            gtmOnCancelEnrollment(game_type, price);
        }
        onUpdate();
    };

    return (
        <>
            <div className="user-enrollment">
                Вы записаны на игру{" "}
                <a href={enrollment.game.url} className="user-enrollment__game-link">
                    {enrollment.game.name}
                    {enrollment.game.host?.name && <> ({enrollment.game.host.name})</>}
                </a>{" "}
                {enrollment.is_cancellable && (
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={() => setShowConfirmModal(true)}
                    >
                        Отменить
                    </button>
                )}
                {enrollment.payment_form_url && (
                    <span className="user-enrollment__payment-notification">
                        Игра не оплачена{" "}
                        <a href={enrollment.payment_form_url} className="btn btn-sm btn-primary">
                            Оплатить
                        </a>
                    </span>
                )}
            </div>
            {showConfirmModal && (
                <CancelConfirmModal
                    enrollment={enrollment}
                    onCancel={cancelParticipation}
                    onHide={() => setShowConfirmModal(false)}
                />
            )}
            {cancelTimeLimit && (
                <CancelTimeLimitModal cancelTimeLimit={cancelTimeLimit} onHide={() => setCancelTimeLimit(null)} />
            )}
        </>
    );
}

export default function UserEnrollments({ enrollments, isDark }) {
    const [enrollmentsToShow, setEnrollmentsToShow] = useState(enrollments);
    const router = useRouter();

    const reloadEnrollments = async () => {
        setEnrollmentsToShow(await fetchUserEnrollments());
        router.reload(window.location.pathname);
    };

    if (!enrollmentsToShow || enrollmentsToShow.length == 0) {
        return null;
    }

    return (
        <div
            className={
                "user-enrollments" +
                (isDark ? " user-enrollments--dark" : "") +
                (enrollmentsToShow.length >= 3 ? " user-enrollments--non-sticky" : "")
            }
        >
            <div className="container-fluid">
                {enrollmentsToShow.map((enrollment) => (
                    <UserEnrollment enrollment={enrollment} onUpdate={reloadEnrollments} key={enrollment.game.id} />
                ))}
            </div>
        </div>
    );
}
