import { useEffect, useState } from "react";

import App from "next/app";

import NextNProgress from "nextjs-progressbar";
import SSRProvider from "react-bootstrap/SSRProvider";

import { CityContext } from "../lib/context/citycontext";
import { UserEnrollmentsContext } from "../lib/context/userenrollmentscontext";
import StaticPage from "./_staticpage";

import "../styles/style.scss";

import { fetchLayout } from "../lib/api/core";
import { handleUTMParams } from "../lib/utm";
import { fetchStaticPage } from "../lib/api/content";
import { setPromocodeCookie } from "../lib/content";
import { UserContext } from "../lib/context/usercontext";

function CashFlowApp({ Component, pageProps }) {
    const [city, setCity] = useState(pageProps.currentCity);
    const [user] = useState(pageProps.user);
    const [userEnrollments, setUserEnrollments] = useState(pageProps.user && pageProps.user.enrollments);

    const cityValue = { city, setCity };
    const userValue = { user };
    const userEnrollmentsValue = { userEnrollments, setUserEnrollments };

    useEffect(() => {
        setUserEnrollments(pageProps.user && pageProps.user.enrollments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageProps.user && pageProps.user.enrollments]);

    const staticPage = pageProps.static_page;

    return (
        <SSRProvider>
            <NextNProgress />
            <CityContext.Provider value={cityValue}>
                <UserContext.Provider value={userValue}>
                    <UserEnrollmentsContext.Provider value={userEnrollmentsValue}>
                        {staticPage && staticPage.title ? <StaticPage {...pageProps} /> : <Component {...pageProps} />}
                    </UserEnrollmentsContext.Provider>
                </UserContext.Provider>
            </CityContext.Provider>
        </SSRProvider>
    );
}

CashFlowApp.getInitialProps = async (appContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);

    const url = (appContext.ctx.asPath || "").split("?")[0];
    const { pageProps } = appProps;

    const query = appContext.ctx.query || {};
    setPromocodeCookie(query, appContext.ctx.req, appContext.ctx.res);
    const cookies = (appContext.ctx.req && appContext.ctx.req.cookies) || {};

    if (pageProps.statusCode != 404) {
        const layoutProps = await fetchLayout(query.city, url, cookies);
        handleUTMParams(query, appContext.ctx.req, appContext.ctx.res, layoutProps.settings.utm_ttl);
        pageProps.layoutProps = {
            topMenuItems: layoutProps.menu,
            footerLinkItems: layoutProps.footer_links,
            bottomMenuBlocks: layoutProps.bottom_menu_blocks,
            counters: layoutProps.counters,
            cities: layoutProps.cities,
            currentCity: layoutProps.current_city,
            user: layoutProps.user,
            sharesInfo: layoutProps.shares_info,
            csrftoken: layoutProps.csrftoken,
            settings: layoutProps.settings,
        };
        pageProps.currentCity = layoutProps.current_city;
        pageProps.user = layoutProps.user;
        pageProps.static_page = layoutProps.static_page;
    } else {
        pageProps.static_page = await fetchStaticPage(url || "", cookies);
    }

    return { ...appProps };
};

export default CashFlowApp;
